/* Importing Fonts */

@font-face {
  font-family: '8_bit_Header';
  src: 
    url('../fonts/04B_30__.TTF')
    format('truetype');
}

@font-face {
  font-family: '8_bit_Paragraph';
  src: 
    url('../fonts/Tecmo_Bowl.ttf')
    format('truetype');
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: '8_bit_Paragraph';
  background-color: #232931;
  font-size: 80%;
  padding-right: 10%;
  padding-left: 10%;

}

a {
  color: #5cecff;
}

a:hover {
  color: #a13eb5;
}

h1 {
  font-size: 225%;
}

.page-content {
  margin: 4%;
  margin-top: 100px;
}

.b-darkest {
  background-color: #232931;
}

.b-dark {
  background-color: #393e46;
}

.white {
  color: #f2f2f2;
}

.highlight {
  color: #ffd700;
}

/* Blink */
.blink {
	-webkit-animation: blink 1.25s linear infinite;
	-moz-animation: blink 1.25s linear infinite;
	-ms-animation: blink 1.25s linear infinite;
	-o-animation: blink 1.25 linear infinite;
	 animation: blink 1.25s linear infinite;
}
@-webkit-keyframes blink {
	0% { opacity: 1; }
	50% { opacity: 1; }
	50.01% { opacity: 0; }
	100% { opacity: 0; }
}
@-moz-keyframes blink {
	0% { opacity: 1; }
	50% { opacity: 1; }
	50.01% { opacity: 0; }
	100% { opacity: 0; }
}
@-ms-keyframes blink {
	0% { opacity: 1; }
	50% { opacity: 1; }
	50.01% { opacity: 0; }
	100% { opacity: 0; }
}
@-o-keyframes blink {
	0% { opacity: 1; }
	50% { opacity: 1; }
	50.01% { opacity: 0; }
	100% { opacity: 0; }
}
@keyframes blink {
	0% { opacity: 1; }
	50% { opacity: 1; }
	50.01% { opacity: 0; }
	100% { opacity: 0; }
}

@media screen and (min-width:1200px) {
  body { font-size:110%; } /* Increase the font size */
}
@media screen and (min-width:1400px) {
  html { padding:0 15%; } /* Reduce the container width */
}
@media screen and (min-width:1600px) {
  body { font-size:125%; } /* Increase the font size */
}
@media screen and (min-width:1800px) {
  html { padding:0 20%; } /* Reduce the container width */
}

/* Navigation */

.navigation {
  background-color: #393e46;
  overflow: hidden;
}

.navigation span {
  font-family: "8_bit_Header";
  float: right !important;
  display: block;
  color: #f2f2f2;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
}

.navigation .brand {
  float: left;
  color: #ffd700;
}

.navigation .active {
  color: #ffd700;
}

.navigation span:hover {
  color: #ffd700;
}

.navigation .icon {
  display: block;
}

.fa-1x {
  font-size: 1.2rem;
}

.navbar-toggler:focus,
.navbar-toggler:active,
.navbar-toggler-icon:focus {
    outline: none;
    box-shadow: none;
}

/* When the screen is less than 600 pixels wide, hide all links. 
Show the hamburger menu */
@media screen and (max-width: 600px) {
  .collapse {display: none;}
  .topnav a.icon {
    float: right;
    display: block;
  }
}

#experience, #projects, #coursework {
  padding-top: 100px;
  margin-top: -50px;
}

/* Page Background */

.welcome-div {
  display: block;
  padding-top: 10%;
}

.list {
  list-style-type: none;
}

.list li {
  padding-top: 10px;
  color: #ffd700;
}

.header {
  margin-top: 8%;
  margin-bottom: 8%;
  position: relative;
}

.header h1 {
  text-align: center;
  color: #ffd700;
  margin-bottom: -3%;
}

/* Introduction */

.code_box {
  border-color: #f2f2f2;
  border-style: inset;
  border-radius: 15px;
  font-family: 'Monaco', 'Courier New';
  padding: 5%;
  background-color: #393e46;
}

.avatar {
  margin-top: 8%;
  display: block;
  text-align: center;
}

.avatar img {
  vertical-align: middle;
  width: 18%;
}

.code_box span {
  color: #f2f2f2;
}

/* Experience */

.expr {
  border-color: #f2f2f2;
  border-style: inset;
  border-radius: 15px;
  font-family: 'Monaco', 'Courier New';
  padding: 5%;
  background-color: #393e46;
  margin-bottom: 25px;
  color: #ffd700;
}

.dates {
  float: right;
  color: #ffd700;
}

.course_dates {
  float: right;
  color: #f2f2f2;
}

.role {
  color: #5cecff;
}

.expr ul {
  list-style-type: none;
  color: #f2f2f2;
}

.expr ul li:before {
  content: '\2014';
  position: absolute;
  margin-left: -20px;
}

/* Projects */

.link_box {
  border-color: #f2f2f2;
  border-style: ridge;
  border-radius: 15px;
  font-family: 'Monaco', 'Courier New';
  width: 100%;
  margin-bottom: 20px;
  padding: 20px;
  color: #393e46;
  background-color: #393e46;
  text-align: center;
  word-wrap: break-word;
}

.link_box h2 {
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 120%;
  color: #ffd700;
}

.link_box h2:hover {
  color: #a13eb5;;
}

.link_box span {
  color: #f2f2f2;
}

.link_box i {
  color: #5cecff;
}

.link_box h2 i {
  color: #ffd700;;
}

.link_box a {
  color: #ffd700;
}

.tag {
  font-size: 75%;
  display: inline-block;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 3px;
  padding-bottom: 3px;
  background-color: #5cecff;
  border-radius: 20px;
  margin-left: 1%;
  margin-right: 1%;
  margin-bottom: 15px;
}

/* Footer */

.footer {
  padding-top: 15px;
  display: block;
  width: 100vw;
  margin-left: calc(-50vw + 50%);
  height: 110px;
  background-color: #393e46;
  text-align: center;
}

.footer h1 {
  color: #ffd700;
  margin-bottom: 10px;
}

.footer h1 i {
  padding: 5px;
  color: #ffd700;
}

.footer span {
  color: #f2f2f2;
}

.coming_soon {
  vertical-align: middle;
}

.coming_soon h1 {
  margin-top: 30%;
  margin-bottom: 30%;
  font-size: 300%;
  color: #ffd700;
  text-align: center;
}

/* Photos */

.photos {
  text-align: center;
}

.photos img {
  margin-top: 20px;
  margin-bottom: 20px;
  max-width: 80%;
}

.photo-header {
  padding-top: 30px;
}

/* Blog */

.blog_header {
  font-size: 200%;
}

.blog {
  border-color: #f2f2f2;
  border-style: inset;
  border-radius: 15px;
  font-family: 'Monaco', 'Courier New';
  padding: 5%;
  background-color: #393e46;
  margin-bottom: 40px;
  color: #ffd700;
  font-size: 120%;
}

.blog p {
  font-size: 120%;
  text-align: center;
}

.tcol {
  width: 100%;
}